<template>
  <div
    class="reflect-checkbox"
    tabindex="0"
    @keyup.enter="onClick"
  >
    <input
      :id="name"
      type="checkbox"
      class="reflect-checkbox__input"
      :checked="value"
      :class="{
        'reflect-checkbox__input--disabled': disabled
      }"
      :disabled="disabled"
      @click="onClick"
    />
    <label
      :for="name"
      class="reflect-checkbox__label"
      :class="{
        'reflect-checkbox__label--empty': !label,
        'reflect-checkbox__label--disabled': disabled,
        [`reflect-checkbox__label--${theme}`]: true
      }"
    >
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ReflectCheckbox',
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'primary'
    }
  },
  emits: ['toggle'],
  methods: {
    onClick() {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss" scoped>
.reflect-checkbox {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    position: relative;
    @include font-text($font-weight-regular);
    @include font-size($font-size-mini);
    align-self: center;
    cursor: pointer;

    &::before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid $text-secondary;
      border-radius: 2px;
      padding: 7px;
      margin: 0 6px 1px 0;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
    }

    &--empty {
      &::before {
        margin: 0;
      }
    }

    &--disabled {
      cursor: not-allowed;

      &::before {
        border: 1px solid $text-tertiary;
      }
    }

    &--primary {
      color: $text-inverse;
    }

    &--secondary {
      color: $text-secondary;
    }
  }

  &__input {
    display: none;
    cursor: pointer;
    user-select: none;
    @include font-size($font-size-mini);

    &:checked + label::before {
      background-color: $bg-tertiary;
    }

    &:checked + label::after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 5px;
      width: 6px;
      height: 9px;
      border: solid $bg-secondary;
      border-width: 0 2px 2px 0;
      border-radius: 1px;
      transform: rotate(45deg);
      box-sizing: border-box;
    }

    &--disabled {
      &:checked + label::after {
        cursor: not-allowed;
      }

      &:checked + label::before {
        background-color: $text-tertiary;
        cursor: not-allowed;
      }
    }
  }
}
</style>
